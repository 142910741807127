import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

const weekEndings = [
  'vasárnap',
  'hétfőn',
  'kedden',
  'szerdán',
  'csütörtökön',
  'pénteken',
  'szombaton',
];

function translate(num: any, withoutSuffix: any, key: any, isFuture: any) {
  switch (key) {
    case 's':
      return isFuture || withoutSuffix
        ? 'néhány másodperc'
        : 'néhány másodperce';
    case 'm':
      return `egy ${isFuture || withoutSuffix ? 'perc' : 'perce'}`;
    case 'mm':
      return `${num} ${isFuture || withoutSuffix ? 'perc' : 'perce'}`;
    case 'h':
      return `egy ${isFuture || withoutSuffix ? 'óra' : 'órája'}`;
    case 'hh':
      return `${num} ${isFuture || withoutSuffix ? ' óra' : ' órája'}`;
    case 'd':
      return `egy ${isFuture || withoutSuffix ? 'nap' : 'napja'}`;
    case 'dd':
      return `${num} ${isFuture || withoutSuffix ? 'nap' : 'napja'}`;
    case 'M':
      return `egy ${isFuture || withoutSuffix ? 'hónap' : 'hónapja'}`;
    case 'MM':
      return `${num} ${isFuture || withoutSuffix ? 'hónap' : 'hónapja'}`;
    case 'y':
      return `egy ${isFuture || withoutSuffix ? 'év' : 'éve'}`;
    case 'yy':
      return `${num} ${isFuture || withoutSuffix ? 'év' : 'éve'}`;
    default:
      return '';
  }
}

function week(this: any, isFuture: any) {
  return `${isFuture ? '' : '[múlt] '}[${weekEndings[this.day()]}] LT[-kor]`;
}

moment.locale(window.locale, {
  months: [
    'január',
    'február',
    'március',
    'április',
    'május',
    'június',
    'július',
    'augusztus',
    'szeptember',
    'október',
    'november',
    'december',
  ],
  monthsShort: [
    'jan',
    'feb',
    'márc',
    'ápr',
    'máj',
    'jún',
    'júl',
    'aug',
    'szept',
    'okt',
    'nov',
    'dec',
  ],
  weekdays: [
    'vasárnap',
    'hétfő',
    'kedd',
    'szerda',
    'csütörtök',
    'péntek',
    'szombat',
  ],
  weekdaysShort: ['vas', 'hét', 'kedd', 'sze', 'csüt', 'pén', 'szo'],
  weekdaysMin: ['v', 'h', 'k', 'sze', 'cs', 'p', 'szo'],
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'YYYY.MM.DD.',
    LL: 'YYYY. MMMM D.',
    LLL: 'YYYY. MMMM D. H:mm',
    LLLL: 'MMMM D.',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'ÉÉÉÉ.H.N.',
    LT: 'Ó:pp',
  },
  meridiemParse: /de|du/i,
  isPM(input) {
    return input.charAt(1).toLowerCase() === 'u';
  },
  meridiem(hours, minutes, isLower) {
    if (hours < 12) {
      return isLower === true ? 'de' : 'DE';
    } else {
      return isLower === true ? 'du' : 'DU';
    }
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[ma] LT[-kor]',
    nextDay: '[holnap] LT[-kor]',
    nextWeek() {
      return week.call(this, true);
    },
    lastDay: '[tegnap] LT[-kor]',
    lastWeek() {
      return week.call(this, false);
    },

    sameYear: 'llll LT[-kor]',
    sameElse: 'll LT[-kor]',
  }),
  relativeTime: {
    future: '%s múlva',
    past: '%s',
    s: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate,
  },
  ordinalParse: /\d{1,2}\./,
  ordinal: () => '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7, // The week that contains Jan 1st is the first week of the year.
  },
});
